<!--外委单位管理-->
<template>
  <page-container title="外委单位情况" :breadcrumb="breadcrumb">
    <!--    查询栏-->
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="公司/项目" prop="userdepid">
              <a-cascader  :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" v-model="queryParams.selectDeptList" :options="deptOptions?deptOptions.filter(item => item.deptype===1):[]" :load-data="loadChildrenDept" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <a-form-model-item label="外委事项" prop="outsourcing_item">
              <a-input v-model.trim="queryParams.outsourcing_item" placeholder="请输入外委事项"></a-input>
            </a-form-model-item>

            <div class="query-btns">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开
                <a-icon :type="showAdvanced?'up':'down'"></a-icon>
              </a-button>
            </div>
          </a-form-model>
        </div>
        <div v-show="showAdvanced" class="advanced-query">
          <a-form-model ref="advancedForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="单位名称" prop="unit">
              <a-input v-model.trim="queryParams.unit" placeholder="请输入单位名称"></a-input>
            </a-form-model-item>
            <a-form-model-item label="资质证" prop="qualification_certificate">
              <a-input v-model.trim="queryParams.qualification_certificate" placeholder="请输入资质证,"></a-input>
            </a-form-model-item>
            <a-form-model-item label="合同价" prop="contract_price">
              <div style="display: flex;flex-direction: row;gap: 10px">
                <a-input v-model.trim="queryParams.contract_price_mini" placeholder="请输入较小值"></a-input>到
                <a-input v-model.trim="queryParams.contract_price_max" placeholder="请输入较大值"></a-input>
              </div>
            </a-form-model-item>
            <a-form-model-item label="服务时间" prop="time_interval">
              <a-range-picker v-model="queryParams.time_interval">
              </a-range-picker>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </div>
    <!--    展示列表栏-->
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">外委单位列表</h3>
          <div class="table-btns">
            <a-button type="primary" @click="showModal('add')">
              <a-icon type="plus"></a-icon>
              新建
            </a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" row-key="id" :loading="tableLoading"
                 :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')"
                 style="margin-top: 20px;" :pagination="false"  :customRow="handleClickRow">
          <span slot="people_type" slot-scope="text" class="outUnitInfo_people_type-view">
            <span class="text">{{ text == '0' ? '物业' : '业主' }}</span>
          </span>
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.id">详情</a-menu-item>
                <a-menu-item :key="'edit-'+record.id">修改</a-menu-item>
                <a-menu-item :key="'delete-'+record.id">删除</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer
                      @change="pageChange" @showSizeChange="onShowSizeChange"
                      :show-total="(total, range) => `共${total}条`"
                      style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <!--    新建、修改外委页面-->
    <a-modal v-model="modalVisible" :title="modalTitle" :width="900" :dialog-style="{top: '25px'}"
             :body-style="modalBodyStyle">
      <template slot="footer">
        <a-button @click="modalCancel">{{modalType==='detail'?'关闭':'取消'}}</a-button>
        <a-button v-if="modalType=='add'||modalType=='edit'"  type="primary" @click="modalConfirm">提交</a-button>
      </template>
      <a-form-model ref="modalForm" :model="modalForm" :rules="modalRules" layout="inline" class="form-in-twocolumns"
                    :label-col="{span:9}" :wrapper-col="{span:14}">
        <a-form-model-item label="公司/项目" prop="userdepid">
          <a-cascader :disabled="modalType==='detail'"  :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" v-model="modalForm.selectDeptList" :options="deptOptions?deptOptions.filter(item => item.deptype===1):[]" :load-data="loadChildrenDept" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
        </a-form-model-item>
        <a-form-model-item label="外委事项" prop="outsourcing_item">
          <a-textarea :disabled="modalType==='detail'" v-model.trim="modalForm.outsourcing_item" placeholder="请输入外委事项"/>
        </a-form-model-item>
        <a-form-model-item label="单位名称" prop="unit">
          <a-input :disabled="modalType==='detail'" v-model="modalForm.unit" placeholder="请输入单位名称"></a-input>
        </a-form-model-item>
        <a-form-model-item label="资质证" prop="qualification_certificate">
          <a-textarea :disabled="modalType==='detail'" v-model="modalForm.qualification_certificate" placeholder="请输入资质证，实行资质管理类的，写明资质；不实行资质管理的，写明：无资质要求"></a-textarea>
        </a-form-model-item>
        <a-form-model-item label="合同价" prop="contract_price">
          <a-input :disabled="modalType==='detail'" v-model="modalForm.contract_price" placeholder="请输入合同价,数字" type="number"></a-input>
        </a-form-model-item>
        <a-form-model-item label="服务时间" prop="time_interval">
          <a-range-picker  :disabled="modalType==='detail'" v-model="modalForm.time_interval">
          </a-range-picker>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </page-container>
</template>
<script>
import {
  addPerformanceOutUnitInfo,
  getOutUnitInfoListByCondition,
  deleteOneOutUnitInfo,
  editOutUnitInfo
} from 'A/performance'
import {
  getItemFromArrayByKey,
} from 'U'
import moment from 'moment'
import {mapState} from "vuex";
import deptselect from '@/mixins/deptselect'
import {userdep} from "V/dataAnalysis/performance/minins/userdep";

export default {
  name: 'outsourcingUnitInfo',
  mixins: [deptselect,userdep],
  data() {
    return {
      showAdvanced: false,
      moment,
      modalBodyStyle: {
        maxHeight: '560px',
        overflowY: 'auto',
      },
      breadcrumb: [
        {
          name: '业务管理',
          path: ''
        },
        {
          name: '履约管理',
          path: ''
        },
        {
          name: '履约数据',
          path: ''
        },
        {
          name: '外委单位',
          path: ''
        }
      ],
      queryParams: {
        outsourcing_item: '',
        unit: '',
        qualification_certificate: '',
        contract_price: '',
        contract_price_mini:'',
        contract_price_max:'',
        time_interval: [],
        userdepid:'',
        selectDeptList:[]
      },
      //外委事项	单位名称	资质证	开始时间	结束时间	合同价
      tableColumns: [
        {
          title: '公司/项目',
          dataIndex: 'userdepname',
          key: 'userdepname'
        },
        {
          title: '外委事项',
          dataIndex: 'outsourcing_item',
          key: 'outsourcing_item'
        },
        {
          title: '单位名称',
          dataIndex: 'unit',
          key: 'unit'
        },
        {
          title: '资质证',
          dataIndex: 'qualification_certificate',
          key: 'qualification_certificate'
        },
        {
          title: '合同价',
          dataIndex: 'contract_price',
          key: 'contract_price'
        },
        {
          title: '合同开始时间',
          dataIndex: 'begin_time',
          key: 'begin_time'
        },
        {
          title: '合同结束时间',
          dataIndex: 'end_time',
          key: 'end_time'
        },
        {
          title: '操作',
          key: 'operation',
          scopedSlots: {customRender: 'operation'}
        },
      ],
      tableData: [],
      tableLoading: false,
      currentPage: 1,
      pagination: {
        total: 0,
        pageSize: 10,
      },
      modalVisible: false,
      modalType: '',
      modalForm: {
        id:0,
        outsourcing_item: '',
        unit: '',
        qualification_certificate: '',
        contract_price: '',
        time_interval: [],
        userdepid:"",
        selectDeptList:[],
      },
      modalRules: {
        outsourcing_item: [{required: true, message: '请输入外委事项'}],
        unit: [{required: true, message: '请输入单位名称'}],
        qualification_certificate: [{required: true, message: '请输入资质证，实行资质管理类的，写明资质；不实行资质管理的，写明：无资质要求'}],
        contract_price: [{required: true, message: '请输入合同价，数字表示'}],
        time_interval: [{required: true, message: '请选择开始和结束时间'}],
        userdepid: [{required: true, message: '请选择公司/项目'}]
      },
      selectedOutUnitInfo:null
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    modalTitle() {
      let title = '';
      if (this.modalType == 'add') {
        title = '新增';
      } else if (this.modalType == 'detail') {
        title = '详情';
      } else if (this.modalType == 'edit') {
        title = '修改';
      } else {
        title = '';
      }
      return title;
    },
    query_begin_time() {
      if (this.queryParams.time_interval && this.queryParams.time_interval[0]) {
        return moment(this.queryParams.time_interval[0]).format('YYYY-MM-DD');
      } else {
        return ''
      }
    },
    query_end_time() {
      if (this.queryParams.time_interval && this.queryParams.time_interval[1]) {
        return moment(this.queryParams.time_interval[1]).format('YYYY-MM-DD');
      } else {
        return ''
      }
    },
    //添加费单时间
    add_begin_time() {
      if (this.modalForm.time_interval && this.modalForm.time_interval[0]) {
        return moment(this.modalForm.time_interval[0]).format('YYYY-MM-DD');
      } else {
        return ''
      }
    },
    add_end_time() {
      if (this.modalForm.time_interval && this.modalForm.time_interval[1]) {
        return moment(this.modalForm.time_interval[1]).format('YYYY-MM-DD');
      } else {
        return ''
      }
    },
  },
  watch: {
    'modalForm.selectDeptList'(val) {
      if(val && val.length) {
        this.modalForm.userdepid = val.join('|')
        this.$refs.modalForm.clearValidate('userdepid');
        // this.initMaintenancegroupidOptions();
      }else {
        this.modalForm.userdepid = ''
      }
    },
    'queryParams.selectDeptList'(val) {
      if(val && val.length) {
        this.queryParams.userdepid = val[val.length-1];
        // this.queryParams.userdepid = val.join('|')
        // this.$refs.queryForm.clearValidate('userdepid');
        // this.initMaintenancegroupidOptions();
      }else {
        this.queryParams.userdepid = ''
      }
    },
  },

  created() {
    this.queryParams.userdepid=this.userInfo.useraccount==='admin'?null:this.userInfo.userdepid
    this.init();
  },
  methods: {
    init() {
      this.getTableData();
    },
    resetQueryParams() {
      this.queryParams.selectDeptList=[]
      this.queryParams.contract_price_mini=''
      this.queryParams.contract_price_max=''
      this.$refs.queryForm.resetFields();
      this.$refs.advancedForm.resetFields();
    },
    getTableData(firstPage) {
      if (firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      // let userdepid = this.queryParams.userdepid;
      // if(userdepid.indexOf('|') > -1) {
      //   userdepid = userdepid.substr(userdepid.lastIndexOf('|')+1);
      // }
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize,
        begin_time: this.query_begin_time,
        end_time:this.query_end_time,
        //userdep_id:this.userInfo.useraccount==='admin'?null:this.userInfo.userdepid,
        // userdepid:this.userInfo.useraccount==='admin'?null:this.userInfo.userdepid,
      };
      getOutUnitInfoListByCondition(params).then(res => {
        this.tableLoading = false;
        if (res && res.returncode === '0') {
          this.tableData = res.item?res.item:[]
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    pageChange(page, size) {
      this.getTableData();
    },
    onShowSizeChange(page, size) {
      this.pagination.pageSize = size;
      this.getTableData(true);
    },
    showModal(type, value, record) {
      this.modalType = type;
      if (type == 'add') {
        this.modalVisible = true;
        this.modalForm.id=0
        this.modalForm.outsourcing_item=''
        this.modalForm.unit= ''
        this.modalForm.qualification_certificate= ''
        this.modalForm.contract_price= ''
        this.modalForm.time_interval= []
        this.modalForm.userdepid= ''
        this.modalForm.selectDeptList= []
      } else {
        this.initUserDeptid(record.userdep_id)
        this.modalVisible = true;
        this.modalForm.id=record.id
        this.modalForm.outsourcing_item=record.outsourcing_item
        this.modalForm.unit= record.unit
        this.modalForm.qualification_certificate= record.qualification_certificate
        this.modalForm.contract_price= record.contract_price
        this.modalForm.time_interval[0]= record.begin_time
        this.modalForm.time_interval[1]= record.end_time
        this.modalForm.userdepid = record.userdep_id
      }
    },
    deleteConfirm(value, record) {
      this.$confirm('确定要删除该数据？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(record.id);
      }).catch(()=>{
      });
    },
    delete(outUnitInfo_id) {
      if (outUnitInfo_id) {
        let params = {
          outUnitInfo_id
        };
        deleteOneOutUnitInfo(params).then(res => {
          if (res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          } else {
            this.$message.error(res.errormsg || '操作失败');
          }
        })
      }
    },
    modalConfirm() {
      if (this.modalType == 'add' || this.modalType == 'edit') {
        this.$refs.modalForm.validate(valid => {
          if (valid) {
            this.addOrEdit();
          } else {
            return false;
          }
        })
      } else {
        this.modalVisible = false;
      }
    },
    //添加或者编辑违约单
    addOrEdit() {
      this.showLoading();
      console.log(this.modalForm)
      let userdepid = this.modalForm.userdepid;
      if(userdepid.indexOf('|') > -1) {
        userdepid = userdepid.substr(userdepid.lastIndexOf('|')+1);
      }
      let params = {
        outsourcing_item:this.modalForm.outsourcing_item,
        unit:this.modalForm.unit,
        qualification_certificate:this.modalForm.qualification_certificate,
        contract_price:this.modalForm.contract_price,
        begin_time:this.add_begin_time,
        end_time:this.add_end_time,
        userdep_id: userdepid
      };
      if (this.modalType == 'add') {
        console.log(params)
        addPerformanceOutUnitInfo(params).then(
            this.addOrEditResult);
      } else if (this.modalType == 'edit') {
        params.id = this.modalForm.id;
        console.log(params)
        editOutUnitInfo(params).then(this.addOrEditResult)
      }
    },
    addOrEditResult(res) {
      this.hideLoading();
      if (res && res.returncode == '0') {
        this.$message.success('操作成功');
        this.modalVisible = false;
        this.getTableData();
      } else {
        this.$message.error(res.errormsg || '操作失败');
      }
    },
    modalCancel() {
      this.modalVisible = false;
    },
    resetModal() {
      this.$refs.modalForm.resetFields();
    },
    operationClick({key}) {
      let arr = key.split('-');
      let type = arr[0];
      let id = arr[1];
      console.log(arr)
      this.selectedOutUnitInfo = getItemFromArrayByKey(this.tableData, 'id', id);
      console.log(this.selectedOutUnitInfo)
      if ('edit' == type) {
        this.showModal('edit', id, this.selectedOutUnitInfo)
      }else if ('detail' == type) {
        this.showModal('detail', id, this.selectedOutUnitInfo)
      }else if ('delete' == type) {
        this.deleteConfirm(id, this.selectedOutUnitInfo)
      }
    },
    handleClickRow(record,index){
      console.log(record)
      return {
        on: {
          click: () => {
            this.operationClick({key:"detail-"+String(record.id)});
          }
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.menu-modal-container {
  // display: flex;
  // position: relative;
  height: 100%;
  overflow-y: auto;
  margin-right: -24px;
}
.outUnitInfo_status-view {
  display: flex;
  align-items: center;
  .dot {
    display: inline-block;
    width: 6px;
    height: 6px;
    background-color: #65ff18;
    border-radius: 50%;
    &.red {
      background-color: #e70c0c;
    }
  }
  .text {
    display: inline-block;
    margin-left: 7px;
  }
}
.outUnitInfo_people_type-view {
  display: flex;
  align-items: center;
  .dot {
    display: inline-block;
    width: 6px;
    height: 6px;
    background-color: #1859ff;
    border-radius: 50%;
    &.green {
      background-color: #65ff18;
    }
  }
  .text {
    display: inline-block;
    margin-left: 7px;
  }
}
</style>